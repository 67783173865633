import { useEffect, useState } from "react";
import "./App.css";
// import qrcode from "qrcode";
import useFirebase from "./useFirebase";

const MINHASH = 17;
function getHash() {
  const hash = document.location.hash;
  if (hash && hash.length > MINHASH) {
    return hash.replace('#', '');
  }
}

let reloadTimeout: NodeJS.Timeout
function setReload() {
  if (reloadTimeout) clearTimeout(reloadTimeout)
  reloadTimeout = setTimeout(function () {
    console.log('reload...')
    window.location.reload()
  }, 60 * 60 * 1000)
}

export default function App() {
  const [uid, code, data, error] = useFirebase(getHash());
  const [url, setUrl] = useState("");
  // const [qr, setQr] = useState<string>("");

  useEffect(() => {
    if (uid && data === null) {
      // qrcode.toDataURL(uid, { width: 392 }).then(setQr);
    }
    if (data && data.feedUrl) {
      if (
        data.feedUrl.startsWith('https://content.seenspire.com/') ||
        data.feedUrl.startsWith('https://staging-content.seenspire.com/')
      ) {
        setUrl(data.feedUrl)
        setReload()
      } else {
        setUrl('')
      }
    } else {
      setUrl('')
    }
  }, [uid, data]);

  return (
    <>
      {error && <div className="socket-error">Offline: {error}</div>}

      {uid && !data && code && (
        <div className="s-content__border">
          <div className="s-content__logo">
            <img src="images/logo.svg" alt="#" />
          </div>
          <div className="s-content__container">
            <div className="s-content__qr">
              { code && <div className="s-content__code">{code}</div> }
            </div>
            <div className="s-content__line"></div>
            <div className="s-content__message">
              <div className="s-content__title">
                Connect your screen
              </div>
              <div className="s-content__instruction">
                <div className="s-content__point">
                  <div className="s-content__number">1</div>
                  <div className="s-content__text">Login to <b>app.seenspire.com</b>.</div>
                </div>
                <div className="s-content__point">
                  <div className="s-content__number">2</div>
                  <div className="s-content__text">Go to the <b>"License Overview"</b> page.</div>
                </div>
                <div className="s-content__point">
                  <div className="s-content__number">3</div>
                  <div className="s-content__text">Enter the <b>6-character code</b> to connect your screen.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {uid && data && !data.feedUrl && (
        <div className="s-content__border">
          <div className="s-content__logo">
            <img src="images/logo.svg"  alt="#" />
          </div>
          <div className="s-content__container">
            <div className="s-content__stream">
              <div className="s-content__stream-image">
                <img src="images/stream-phone.png"  alt="#" />
              </div>
              <div className="s-content__stream-message">
                <div className="s-content__connected">
                  <div className="s-content__connected-img">
                    <img src="images/success.svg" alt="ok"/>
                  </div>
                  <div className="s-content__connected-text">Connected</div>
                </div>
                <div className="s-content__stream-instruction">
                  <div className="s-content__stream-title">
                    Play stream
                  </div>
                  <div className="s-content__stream-text">
                    To play a stream, simply select a stream within the Seenspire mobile app.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {uid && data && data.feedUrl && url && (
        <iframe
          className="s-presentation__content"
          title="presentation"
          frameBorder="none"
          src={url}
        ></iframe>
      )}
    </>
  );
}
